var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vs-popup',{attrs:{"classContent":"popup-example","title":"Confirm Update","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('ValidationProvider',{attrs:{"name":"policy_password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"danger":!!errors[0],"danger-text":errors[0],"type":"password","name":"policy_password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label-placeholder":"Password"},model:{value:(_vm.policy_password),callback:function ($$v) {_vm.policy_password=$$v},expression:"policy_password"}})]}}],null,true)}),_c('vs-button',{staticClass:"mb-2 mt-5",attrs:{"disabled":invalid,"type":"filled"},on:{"click":_vm.UpdatePolicy}},[_vm._v("Confirm")])],1)]}}])})],1),_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8",attrs:{"title":"Update Policy","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Title ")]),_c('validation-provider',{attrs:{"name":"police_title","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-chevron-right","icon-no-border":"","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.policy_title),callback:function ($$v) {_vm.policy_title=$$v},expression:"policy_title"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("COMPANY ")]),_c('validation-provider',{attrs:{"name":"company_id","rules":"required |min:1"}},[_c('v-select',{staticClass:"mb-4",attrs:{"label":"name","reduce":function (item) { return item.id; },"options":_vm.companies,"clearable":false,"multiple":"","dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"placeholder":"Company"},model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}})],1)],1),_c('div',{staticClass:"vx-col w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Upload File ")]),_c('a',{on:{"click":_vm.downloadFile}},[_vm._v(_vm._s(_vm.policy_file))]),(!_vm.isUserUpdateFile)?_c('div',{staticClass:"con-img-upload"},[_c('vs-images',[_c('vs-image',{attrs:{"src":_vm.policy_file}})],1)],1):_vm._e(),_c('vs-upload',{ref:"getFile",attrs:{"limit":"1"},on:{"change":_vm.getFile}})],1)])]),_c('vs-button',{staticClass:"mb-2",attrs:{"disabled":invalid,"type":"filled"},on:{"click":function($event){return _vm.openPopUp()}}},[_vm._v("SUBMIT")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }