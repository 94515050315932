<!--*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            name="policy_password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="policy_password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="policy_password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="UpdatePolicy"
            class="mb-2 mt-5"
            >Confirm</vs-button>
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Update Policy"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-col w-full mb-2">
                <label class="text-sm opacity-75">
                  <span class="text-primary">*</span>Title
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="police_title"
                  rules="required|min:1"
                >
                  <vs-input
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                    icon-no-border
                    v-model="policy_title"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  />
                </validation-provider>
              </div>
                    <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY
                  </label>
                  <validation-provider  name="company_id" rules="required |min:1">
                 
                    <v-select
                      label="name"
                      :reduce="item => item.id"
                      :options="companies"
                      :clearable="false"
                      multiple
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="company_id"
                      class="mb-4"
                      placeholder="Company"
                    />
                  
                  </validation-provider>
                </div>
              <div class="vx-col w-full mb-2">
                <label class="text-sm opacity-75">
                  <span class="text-primary">*</span>Upload File
                </label>
                <a @click="downloadFile">{{policy_file}}</a>
                <div v-if="!isUserUpdateFile" class="con-img-upload">
                  <!-- <div class="con-input-upload"> -->
                  <vs-images>
                    <vs-image :src="policy_file" />
                  </vs-images>
                </div>
                <vs-upload ref="getFile" @change="getFile" limit="1" />
              </div>
            </div>
          </div>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="openPopUp()"
            class="mb-2"
            >SUBMIT</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import vSelect from "vue-select";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty"
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters"
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      title: null,
      policy_file: null,
      policy_title: null,
      policy_password: null,
     
      company_id:null,
      popupActive: false,
      isUserUpdateFile: false,
      fileRequired: false
    };
  },
  computed: {
    itemsData() {
      return this.$store.state.policies.items;
    },
      companies() {
      return this.$store.state.company.items;
    },
  },
  methods: {
    openPopUp() {
      this.popupActive = true;
    },
    downloadFile(){
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = this.policy_file;
      downloadLink.target = "_blank";

      downloadLink.download = 'fileName';
      downloadLink.click();

    },
    getFile() {
      this.policy_file = this.$refs.getFile.filesx[0];
      
      this.isUserUpdateFile = true;
    },
    UpdatePolicy() {
      if (!this.policy_file) {
        this.$vs.notify({ color: "danger", title: "File is Required" });
      } else {
        this.$vs.loading();
        this.popupActive = false;
        const formData = new FormData();
        if (this.policy_file["type"]) {
          formData.append("file", this.policy_file);
        }
        formData.append("title", this.policy_title);
         this.company_id.forEach((element, i) => {
        formData.append("companies[" + i + "]", element);
      });
        formData.append("_method", "PUT");
        formData.append("password", this.policy_password);
        axios
          .post(
            `${process.env.VUE_APP_BASE_URI}/api/admin/policies/${this.$route.query.SID}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
          .then(response => {
            this.$vs.loading.close();
            if (response.status === 200) {
              this.$vs.notify({
                title: "Success",
                text: "Policy is updated",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });

              this.$router.push("../GetAll/Policies");
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Policy is not updated",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          });
      }
    },
    goToPolicyList() {
      this.$router.push("../GetAll/Policies");
    },
    getById() {
      axios
        .get(
          `${process.env.VUE_APP_BASE_URI}/api/admin/policies/${this.$route.query.SID}`
        )
        .then(res => {
          const x = res.data.response.data;
           this.policy_title = x.title;
          // console.log( x.file);
          this.policy_file = x.file;
         this.company_id = x.companies
        });
    },
      getCompanies() {
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
    }
  },
  mounted() {
    this.getById();
    this.getCompanies();
  },
   created() {
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
  },
};
</script>
